<template>
  <div>
    <!-- <div class="title">学习中心首页</div> -->
    <!-- <div class="tab">
      <div class="tab_item">
        <div class="tab_item_1">今天上课</div>
        <div class="tab_item_2">
          <span>{{ report.classHour.hour ? report.classHour.hour : "0" }}</span>
          <span>小时</span>
          <span>{{ report.classHour.min ? report.classHour.min : "00" }}</span>
          <span>分</span>
        </div>
      </div>
      <div class="tab_item">
        <div class="tab_item_1">累计学习课程</div>
        <div class="tab_item_2">
          <span>{{ report.class || 0 }}</span
          >节
        </div>
      </div>
      <div class="tab_item">
        <div class="tab_item_1">签到天数</div>
        <div class="tab_item_2">
          <span>{{ report.sign || 0 }}</span
          >天
        </div>
      </div>
    </div> -->

    <van-notice-bar
      scrollable
      left-icon="volume-o"
      text="尊敬的用户您好！因系统升级，由此带来的不便敬请谅解。"
    />
    <div class="title1">
      <span class="span_1">学习班级</span>
      <span class="span_2" @click="goclass">更多班级</span>
    </div>
    <div class="module">
      <img
        v-if="show"
        class="leftbtn"
        @click="zd"
        @mouseenter="enter"
        src="../../assets/zjt.png"
      />
      <img
        @mouseenter="enter"
        v-if="show"
        class="rigthbtn"
        @click="yd"
        src="../../assets/yjt.png"
      />
      <div class="box" @mouseenter="enter" @mouseleave="leave">
        <div class="courselist" ref="yd">
          <div
            v-for="(item, index) in list"
            :key="index"
            :class="item.flag == true ? 'items active' : 'items'"
            @click="cut(index, item)"
          >
            <div :class="item.flag == true ? 'title active' : 'title'">
              学习课程：{{ item.title }}
            </div>
            <div :class="item.flag == true ? 'time active' : 'time'">
              时间：{{ item.starttime }}～{{ item.endtime }}到期
            </div>
            <!-- <div
              style="
                display: flex;
                margin-top: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
              "
            >
              <el-progress
                :show-text="false"
                style="width: 160px"
                :percentage="item.ksSpeed"
                :color="item.flag == true ? '#fe9549' : '#409eff'"
              ></el-progress>
              <span style="transform: translateY(-5px); margin-left: 30px">
                学习进度</span
              >
            </div> -->
          </div>
        </div>
      </div>
      <div class="content">
        <!-- <div class="titles">
          班级：{{ title || "暂无" }} &nbsp;&nbsp;&nbsp; 学习进度：{{
            detail.classHour || "暂无"
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系老师
        </div> -->
        <div class="titles">
          班级：{{ title || "暂无" }}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系老师
        </div>
        <div class="statisticslist">
          <!-- <div class="item">
            <div class="item_1">课程学习时长</div>
            <div class="item_2">
              {{ detail.study || 0 }}<span> 课时</span>
              <span
                class="item_3"
                @click="dialogVisible = true"
                v-if="
                  row.class.audio_time == 1 ||
                  row.class.h5_time == 1 ||
                  row.class.video_time == 1 ||
                  row.class.practice_time == 1 ||
                  row.class.exam_time == 1 ||
                  row.broadcast_time != '00:00'
                "
                >学习详情</span
              >
            </div>
          </div> -->
          <!-- <div class="item">
            <div class="item_1">课程学习进度</div>
            <div class="item_2">
              {{ detail.classHour || "暂无" }}
            </div>
          </div> -->
          <div class="item">
            <div class="item_1">累计签到次数</div>
            <div class="item_2">{{ detail.signIn || 0 }} <span> 天</span></div>
          </div>
          <div class="item">
            <div class="item_1">课程答疑</div>
            <div class="item_2">{{ detail.answer || 0 }} <span> 次</span></div>
          </div>
        </div>

        <div class="titless">学习报告</div>
        <div class="classlist">
          <div class="item a">
            <div class="item_1">
              <div class="item_1_1">签到注册</div>
              <div class="item_1_2">
                {{ detail.signIn || 0 }} <span>天</span>
              </div>
            </div>
          </div>
          <div class="item b">
            <div class="item_1">
              <div class="item_1_1">学习记录</div>
              <div class="item_1_2">
                {{ detail.study || 0 }} <span>分钟</span>
              </div>
            </div>
          </div>
          <div class="item c">
            <div class="item_1">
              <div class="item_1_1">答疑辅导</div>
              <div class="item_1_2">
                {{ detail.answer || 0 }} <span>次</span>
              </div>
            </div>
          </div>
          <div class="item d">
            <div class="item_1">
              <div class="item_1_1">评价测试</div>
              <div class="item_1_2" v-if="detail.score">
                {{ detail.score || 0 }} <span>分</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="reportlist">
          <div class="item">
            <img src="../../assets/report1.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                音频课
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.audio"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report2.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                HTML5课
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.html5"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report3.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                视频课
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.video"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report4.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                章节练习
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.chapter"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report5.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                题库练习
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.Total"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="../../assets/report6.svg" alt="" />
            <div>
              <div
                style="
                  margin-bottom: 8px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                "
              >
                综合测试
              </div>
              <div style="width: 200px">
                <el-progress
                  color="#FF743E"
                  :percentage="detail.questions"
                  :show-text="false"
                ></el-progress>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- <el-dialog title="课程学习时长" :visible.sync="dialogVisible" width="585">
      <div class="reportlist1">
        <div class="item" v-if="row.class.audio_time == 1">
          <img src="../../assets/report1.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              音频课时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.audio_time ? row.audio_time : "00:00" }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.class.h5_time == 1">
          <img src="../../assets/report2.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              HTML5时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.h5_time ? row.h5_time : "00:00" }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.class.video_time == 1">
          <img src="../../assets/report3.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              视频课时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.video_time ? row.video_time : "00:00" }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.class.practice_time == 1">
          <img src="../../assets/report4.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              答疑练习时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.practice_time ? row.practice_time : "00:00" }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.class.exam_time == 1">
          <img src="../../assets/report6.svg" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              综合测试时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.exam_time ? row.exam_time : "00:00" }}
            </div>
          </div>
        </div>
        <div class="item" v-if="row.broadcast_time != '00:00'">
          <img src="../../assets/scereen.png" alt="" />
          <div>
            <div
              style="
                margin-bottom: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              直播课学习时长
            </div>
            <div style="width: 200px; color: #ff5f00; font-size: 16px">
              {{ row.broadcast_time ? row.broadcast_time : "00:00" }}
            </div>
          </div>
        </div>
      </div>
      <div class="xian" v-if="row.class.actual_time == 1"></div>
      <div
        style="display: flex; margin: 20px 0 0 20px"
        v-if="row.class.actual_time == 1"
      >
        <img
          style="margin-right: 16px; width: 36px; height: 36px"
          src="../../assets/learn.jpg"
          alt=""
        />
        <div>
          <div
            style="
              margin-bottom: 8px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            "
          >
            学习时长( 音频 | HTML5 | 视频 | 直播 )
          </div>
          <div style="width: 200px; color: #ff5f00; font-size: 16px">
            {{ row.actual_time ? row.actual_time : "00:00" }}
          </div>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
      report: {},
      detail: {},
      distance: 0,
      avtive: 0,
      index: 0,
      tiem: 0,
      ks: 0,
      time: 0,
      time: null,
      flag: true,
      list: [],
      title: "",
      row: {},
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },

  methods: {
    ...mapActions([
      "getMinePace",
      "getPresentation",
      "getMinePaceDetail",
      "getmyClass_x",
      "getstudyReport_x",
      "getstudyReportFromDay_x",
      "fiveCount_x",
    ]),
    //向右移动
    yd() {
      if (this.list.length < 3) return;
      if (this.index > -1 && this.index < this.list.length - 3) {
        if (this.flag == true) {
          this.index += 1;
          this.flag = false;
          this.distance += 305;
          let num = this.distance - 305;

          this.time = setInterval(() => {
            num += 10;
            if (num > this.distance) {
              clearInterval(this.time);
              this.flag = true;
              this.$refs.yd.style.right = `${this.distance}px`;
            } else {
              this.$refs.yd.style.right = `${num}px`;
            }
          }, 10);
        }
      }
    },
    //向左移动
    zd() {
      if (this.list.length < 3) return;
      if (this.index > 0 && this.index <= this.list.length) {
        if (this.flag == true) {
          this.index -= 1;

          this.flag = false;
          this.distance -= 305;
          let num = this.distance + 305;
          this.time = setInterval(() => {
            num -= 10;
            if (num < this.distance) {
              clearInterval(this.time);
              this.flag = true;
              this.$refs.yd.style.right = `${this.distance}px`;
            } else {
              this.$refs.yd.style.right = `${num}px`;
            }
          }, 10);
        }
      }
    },
    //更多班级
    goclass() {
      this.$router.push("/Layout/mynterpretation");
    },
    // 移入 移出事件
    enter() {
      this.show = true;
    },
    leave() {
      this.show = false;
    },
    // 课程详情
    cut(index, item) {
      this.list.forEach((e, i) => {
        e.flag = i == index ? true : false;
      });
      this.title = item.title;
      // this.fiveCount_x({
      //   username: this.userInfo.username,
      //   class_id: item.id,
      //   student_id: this.userInfo.id,
      // }).then((res) => {
      //   if (res.successCode == 200) {
      //     this.row = res.responseBody;
      //   }
      // });
      this.getstudyReport_x({
        student_id: this.userInfo.id,
        class_id: item.id,
      }).then((res) => {
        if (res.successCode == 200) {
          // res.responseBody.audio = +res.responseBody.audio.replace("%", "");
          // res.responseBody.html5 = +res.responseBody.html5.replace("%", "");
          // res.responseBody.video = +res.responseBody.video.replace("%", "");
          // res.responseBody.chapter = +res.responseBody.chapter.replace("%", "");
          // res.responseBody.Total = +res.responseBody.practice.replace("%", "");
          // res.responseBody.questions = +res.responseBody.status.replace(
          //   "%",
          //   ""
          // );
          // res.responseBody.classHour = +res.responseBody.classHour.replace(
          //   "%",
          //   ""
          // );
          // res.responseBody.classHour =
          //   +res.responseBody.classHour.toFixed(0) + "%";
          this.detail = res.responseBody;
        }
      });
    },
  },
  mounted() {
    // 列表数据
    this.getmyClass_x({
      student_id: this.userInfo.id, //学员id
      title: "", //班级名称
      type: "1", //1. 正在学 2.已完成
      form: "1", //1. 显示进度条 2. 不显示
      page: "1",
      pageSize: "100",
    }).then((res) => {
      if (res.successCode == 200) {
        if (res.message != "暂无数据!") {
          res.responseBody.list.forEach((e, i) => {
            e.starttime = e.start.substring(0, 10);
            e.endtime = e.end.substring(0, 10);
            // e.ksSpeed = +e.ksSpeed.replace("%", "");
            if (i == 0) {
              e.flag = true;
            } else {
              e.flag = false;
            }
          });
          this.title = res.responseBody.list[0].title;
          this.list = res.responseBody.list;
          // this.fiveCount_x({
          //   username: this.userInfo.username,
          //   class_id: res.responseBody.list[0].id,
          //   student_id: this.userInfo.id,
          // }).then((res) => {
          //   if (res.successCode == 200) {
          //     this.row = res.responseBody;
          //   }
          // });
          this.getstudyReport_x({
            student_id: this.userInfo.id,
            class_id: res.responseBody.list[0].id,
          }).then((res) => {
            if (res.successCode == 200) {
              // res.responseBody.audio = +res.responseBody.audio.replace("%", "");
              // res.responseBody.html5 = +res.responseBody.html5.replace("%", "");
              // res.responseBody.video = +res.responseBody.video.replace("%", "");
              // res.responseBody.chapter = +res.responseBody.chapter.replace(
              //   "%",
              //   ""
              // );
              // res.responseBody.Total = +res.responseBody.practice.replace(
              //   "%",
              //   ""
              // );
              // res.responseBody.questions = +res.responseBody.status.replace(
              //   "%",
              //   ""
              // );
              // res.responseBody.classHour = +res.responseBody.classHour.replace(
              //   "%",
              //   ""
              // );
              // res.responseBody.classHour =
              //   +res.responseBody.classHour.toFixed(0) + "%";
              this.detail = res.responseBody;
            }
          });
        }
      }
    });
    this.getstudyReportFromDay_x({
      student_id: this.userInfo.id,
      type: 1,
    }).then((res) => {
      if (res.successCode == 200) {
        this.report = res.responseBody;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.classlist {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    display: flex;
    margin-bottom: 35px;
    justify-content: space-between;
    width: calc(50% - 17px);
    height: 148px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.26);
    // background: url("../../assets/g_2.png") right bottom no-repeat;
    // background-size: 50%;
    .item_1 {
      margin: 28px;
      .item_1_1 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        &::before {
          display: inline-block;
          content: "";
          width: 10px;
          height: 10px;
          background: #ff5f00;
          border-radius: 50%;
          margin-right: 11px;
        }
      }
      .item_1_2 {
        margin-left: 16px;
        font-size: 50px;
        font-family: ArialMT;
        color: #ff5f00;
        span {
          font-size: 12px;
          font-family: HiraginoSansGB-W3, HiraginoSansGB;
          font-weight: normal;
          color: #000000;
        }
      }
    }
    img {
      width: 236px;
    }
  }
  .a {
    background: url("../../assets/g_1.png") right bottom no-repeat;
    background-size: 50%;
  }
  .b {
    background: url("../../assets/g_2.png") right bottom no-repeat;
    background-size: 50%;
  }
  .c {
    background: url("../../assets/g_3.png") right bottom no-repeat;
    background-size: 50%;
  }
  .d {
    background: url("../../assets/g_4.png") right bottom no-repeat;
    background-size: 50%;
  }
}
.reportlist1 {
  margin-top: 43px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  .item {
    display: flex;
    width: 30%;
    margin-left: 20px;
    margin-bottom: 45px;

    img {
      margin-right: 16px;
      width: 36px;
      height: 36px;
    }
  }
}
.xian {
  width: 100%;
  height: 1px;
  border: 1px solid #cfcfcf;
}
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.leftbtn {
  position: absolute;
  cursor: pointer;
  left: 0px;
  top: 50px;
  z-index: 999;
}
.rigthbtn {
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: 50px;
  z-index: 999;
}
.tab {
  margin-top: 14px;
  height: 170px;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .tab_item_1 {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .tab_item_2 {
    margin-top: 9px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    span {
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
  }
}
.module {
  position: relative;
  margin-top: 22px;
  background: #ffffff;
  .box {
    display: -webkit-box;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .courselist {
    position: relative;
    display: flex;
    box-sizing: border-box;

    .active {
      color: #ffffff !important;
      background: #137cfb !important;
      &::before {
        display: block;
        content: "";
        background: #137cfb;
      }
    }
    .items {
      padding: 24px;
      box-sizing: border-box;
      position: relative;
      width: 298px;
      height: 133px;
      background: #f8f8f8;
      margin-right: 7px;
      color: #000;
      &:hover {
        border: 1px solid #23b8ff;
        padding: 23px !important;
      }
      .title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
      }
      .time {
        margin-top: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
      }
    }
  }
  .content {
    background-color: #fff;
    padding: 27px;
    box-sizing: border-box;
    .titles {
      height: 46px;
      border-bottom: 1px solid #e1e1e1;
    }
    .statisticslist {
      height: 163px;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      div {
        text-align: center;
      }
      .item_1 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b5b5b6;
      }
      .item_2 {
        margin-top: 8px;
        font-size: 36px;
        font-family: ArialMT;
        color: #ff5f00;
        span {
          font-size: 14px;
          font-family: HiraginoSansGB-W3, HiraginoSansGB;
          font-weight: normal;
          color: #000000;
        }
        .item_3 {
          cursor: pointer;
          transform: translateY(-5px);
          margin-left: 10px;
          display: inline-block;
          width: 68px;
          height: 31px;
          line-height: 31px;
          text-align: center;
          background: #e8f0fa;
          border: 2px solid #ccd6e2;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7c7c7c;
        }
      }
    }
    .titless {
      margin-top: 34px;
      font-size: 21px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .reportlist {
      margin-top: 43px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      .item {
        display: flex;
        width: 30%;
        margin-bottom: 64px;
        margin-right: 30px;
        img {
          margin-right: 16px;
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
.title1 {
  display: flex;
  justify-content: space-between;
  .span_1 {
    font-size: 21px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .span_2 {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
</style>
